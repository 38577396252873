import React from 'react';
import { releases } from '../assets/data/releases';
import Thea from '../assets/graphics/theas/thea-music.png';
import './ListReleases.css'; // Import the CSS for styling
import CloudTop from '../assets/graphics/cloud-footer-old.png';
import CloudsBottom from '../assets/graphics/cloud-down-old.png';

const ListReleases = () => {
    // Filter releases by category
    const labelReleases = releases.filter(release => release.category === 'label');
    const musicReleases = releases.filter(release => release.category === 'music');

    return (
        <>
            <div className='container-rl'>
                {/* Thea section with image */}
                <div className="thea">
                    <img src={Thea} alt="podcast" className="thea-image-rl" />
                    <h1 className='title-rl'>My Label & Music</h1>
                </div>

                {/* Results section */}
                <div className="resultsPodcast">
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-top" />
                    </div>

                    <div className="main-container">
                        <div className="inner-container">

                            {/* Label Section */}
                            <div className="release-section">
                                <h2 className="section-title">Label</h2>
                                <div className='listResult'>
                                    {labelReleases.map(release => (
                                        <a href={release.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="allReleasesLink"
                                            key={release.name}
                                            role="button"
                                            style={{ cursor: 'pointer' }} /* Force cursor pointer in inline styles */
                                        >
                                            <div className="release-item">
                                                <p className="release-name">{release.name}</p>
                                                <p className="release-details">{release.label}, {release.year}</p>
                                            </div>
                                        </a>

                                    ))}
                                </div>
                            </div>

                            {/* Music Section */}
                            <div className="release-section">
                                <h2 className="section-title">My Music</h2>
                                {musicReleases.map(release => (
                                    <a href={release.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="allReleasesLink"
                                        key={release.name}
                                        role="button"
                                        style={{ cursor: 'pointer' }} /* Force cursor pointer in inline styles */
                                    >
                                        <div className="release-item">
                                            <p className="release-name">{release.name}</p>
                                            <p className="release-details">{release.label}, {release.year}</p>
                                        </div>
                                    </a>
                                ))}
                            </div>

                        </div>
                    </div>

                    {/* Clouds at the bottom */}
                    <div className='cloudsBottom'>
                        <img src={CloudsBottom} alt="cloudBottom" className="cloud-bottom" />
                    </div>
                    <div className='space-footer'></div>
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-top" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListReleases;
