import React from 'react';
import { episodes } from '../assets/data/dreamingLive';
import Thea from '../assets/graphics/theas/thea-podcast.png';
import Fav from "../assets/graphics/podcast/fav.png"
import './ListPodcast.css'; // Import the CSS for styling
import CloudTop from '../assets/graphics/cloud-footer.png';
import CloudsBottom from '../assets/graphics/cloud-down.png';


const ListPodcast = () => {
    // Ensure episodes is defined, if not fallback to an empty array
    const pinnedEpisodes = (episodes || [])
        .filter(episode => episode.pinned !== null) // Filter where pinned is not null
        .sort((a, b) => a.pinned - b.pinned); // Sort by pinned number

    const allEpisodes = (episodes || []).sort((a, b) => a.episode - b.episode); // Sort all episodes by episode number

    return (
        <>
            <div className='container-dl'>
                <div className="thea">
                    <img src={Thea} alt="podcast" className="thea-image-dl" />
                    <h3>Dreaming Live</h3>
                    <p className='titleText'>
                        Dedicated to uncompromised creative expression.<br></br>
                        Art as the Voice of our Innermost, as opposed to Art as Entertainment.
                        <br></br>Dreaming Live is to Be, who we choose to Be.
                        <br></br>From our state of Being, to Dream.
                        <br></br>For Our Dreams, to Create.
                        <br></br>Lastly, against all odds, it is to Persist.
                    </p>
                </div>

                <div className="resultsPodcast">
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-top" />
                    </div>

                    {/* Pinned Section */}
                    <div className='resultsDiv'>
                        {/* All Episodes Section */}
                        <div className='results'>
                            {allEpisodes.map((episode) => (
                                <a href={episode.link} target="_blank" rel="noopener noreferrer" className="allEpisodesLink">

                                    <div className='listEpisode' key={episode.episode}>
                                        <div className="episode-number"> Episode {episode.episode.toString().padStart(2, '0')}</div> {/* Add a leading zero if necessary */}

                                        <div className="artist">{episode.artist}</div>
                                        <div className="episode-title">{episode.title}
                                            {episode.pinned ? (
                                                <span className='fav'>💖 Fav</span>
                                            ) : null}

                                        </div>





                                    </div>
                                </a>
                            ))}
                        </div>

                    </div>

                    <div className='cloudsBottom'>
                        <img src={CloudsBottom} alt="cloudBottom" className="cloud-bottom" />
                    </div>
                    <div className='space-footer'>
                    </div>
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-top" />
                    </div>
                </div>
            </div >

        </>
    );
};

export default ListPodcast;
