export const mixes = [
  {
    "date": "02/14/2024",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Thank you Knekelhuis",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "Dreamy & Wavey.",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-thank-you"
  },
  {
    "date": "03/13/2024",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Thank you Offen Music",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "Psychedelic, slow burning, cosmic music.",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-thank-you-1"
  },
  {
    "date": "04/10/2024",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Thank you Bedouin Records",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": true,
    "cosmic": false,
    "pinned": 4,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "Celebrating 10 years of the amazing Bedouin records, and its infinity in range of sonic realms, from house and techno to insane experimental jazzy maddness",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-thank-you-2"
  },
  {
    "date": "05/08/2024",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Thank you Drowned By Locals",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": true,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "One of my favorite labels! Weird , industrial grooves and non grooves— & some amazing tunes by Al-Mutreb Abul-Loul",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-thank-you-3"
  },
  {
    "date": "07/10/2024",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Thank You Tresor ",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": true,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "House and Techno from Tresor!",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-thank-you-4"
  },
  {
    "date": "09/11/2024",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Thank You Psychic Liberation",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": true,
    "cosmic": false,
    "pinned": 1,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "Dance music with no rules nor formulas, deconstructs into ambient and drones.",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-thank-you-6"
  },
  {
    "date": "12/19/2023",
    "radio": "Kiosk Radio",
    "radioName": "Kiosk Radio: Outsiders",
    "showName": "Memphis HipHop Mix",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": true,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": false,
    "description": "Sexy Memphis hip-hop.",
    "city": "Brussels",
    "link": "https://soundcloud.com/kioskradio/outsiders-mayss-kiosk-radio-19"
  },
  {
    "date": "01/15/2024",
    "radio": null,
    "radioName": "Radio Rituel",
    "showName": "Radio Rituel 67: Mayss",
    "listening": true,
    "dance": true,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Aiming for the Sun's transcendent yellow and gold, through both beats and non beats.",
    "city": null,
    "link": "https://soundcloud.com/user-584333214/radio-rituel-67-mayss"
  },
  {
    "date": "02/21/2024",
    "radio": "Operator Radio",
    "radioName": "Operator Radio",
    "showName": "Mutant Modality w Mayss",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": true,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Slow Burning house, techno, industrial & EBM",
    "city": null,
    "link": "https://soundcloud.com/mutantmodality/mutant-modality-w-mayss-21022024"
  },
  {
    "date": "07/15/2024",
    "radio": "Kaos London",
    "radioName": "Kaos London",
    "showName": "KAOS London podcast #28 Mayss",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": 5,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Bangin' Techno but not techno. Weirdo/messed up dance",
    "city": "London",
    "link": "https://soundcloud.com/user-117182270/kaos-london-podcast-28-mayss"
  },
  {
    "date": "06/5/2019",
    "radio": "NTS Radio",
    "radioName": "NTS Radio",
    "showName": "Bokeh Versions wt Mayss",
    "listening": false,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Industrial/experimental music. Kind of terrorizing.",
    "city": "London",
    "link": "https://www.mixcloud.com/NTSRadio/bokeh-versions-w-mayss-5th-june-2019/"
  },
  {
    "date": "10/27/2023",
    "radio": "Lyl Radio",
    "radioName": "Lyl Radio",
    "showName": "Mayss: For Palestine",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Pain...",
    "city": "Marseille",
    "link": "https://soundcloud.com/lyl_radio/mayss-271023"
  },
  {
    "date": "12/22/2023",
    "radio": null,
    "radioName": "Sleepers Records",
    "showName": "Mayss: Winter Solistice",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Celebrating Winter Solistice with Musick for the Moon.",
    "city": "London",
    "link": "https://soundcloud.com/sleepersound-2/mayss?in=mmaayyss/sets/my-shows-on-mutant-radio&si=f1183b2683794e0b8f9b6097c33e0f70&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
  },
  {
    "date": "02/23/2024",
    "radio": "Kasheme Radio",
    "radioName": "Kasheme Radio",
    "showName": "Waking Life Festival: Mayss",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": false,
    "pinned": 5,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Dedicated to Inner Stillness. A concept I pursue to cleanse and purge myself in orderto become the Void and attain Infinity.",
    "city": "Zurich",
    "link": "https://soundcloud.com/wakinglifeportugal/mayss-at-kasheme-for-waking-life-radio?in=mmaayyss/sets/my-shows-on-mutant-radio"
  },
  {
    "date": "05/20/2021",
    "radio": "Traumcast",
    "radioName": "Traumcast",
    "showName": "Traumcast 03 Mayss",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Bright Yellow Rays of the Transcendent Sun.",
    "city": "Rotterdam",
    "link": "https://soundcloud.com/traumgarten/traumcast-03-mayss?in=traumgarten%2Fsets%2Ftraumcast"
  },
  {
    "date": "02/15/2021",
    "radio": "Chambre Noir",
    "radioName": "Chambre Noir",
    "showName": "Chambre noir wt Mayss",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Stillness, but with pain.",
    "city": "Montreal",
    "link": "https://soundcloud.com/chambrenoirerecords/55-mayss?in=mmaayyss/sets/my-shows-on-mutant-radio"
  },
  {
    "date": "05/15/2019",
    "radio": "Intergalatic FM",
    "radioName": "Intergalatic FM",
    "showName": "Knekelhuis 55: Mayss",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Aiming for the Bright Yellow Rays of the Transcendent Sun. ",
    "city": "The Hague",
    "link": "https://soundcloud.com/knekelhuis/ifm-55-mayss-knekelhuis-intergalactic-fm"
  },
  {
    "date": "08/08/2020",
    "radio": "Radio AlHara",
    "radioName": "Radio AlHara",
    "showName": "Pharoah and Fairouz",
    "listening": true,
    "dance": false,
    "existential": false,
    "transcendent": true,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Dedicated to the Liberation of the Soul attained through Arabic and Black Music\u2014 free jazz in particular.",
    "city": "Bethlehem",
    "link": "https://soundcloud.com/dreaming-live/p-f"
  },
  {
    "date": "08/08/2024",
    "radio": "Radio AlHara",
    "radioName": "Radio AlHara",
    "showName": "To Inner Stillness",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": false,
    "pinned": 2,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "To Inner Stillness, and the Infinity attained in becoming nothing.",
    "city": "Bethlehem",
    "link": "https://soundcloud.com/dreaming-live/mayss-for-asghar"
  },
  {
    "date": "11/23/2021",
    "radio": "Kiosk",
    "radioName": "Kiosk Radio",
    "showName": "Mediteranos w/ Mayss ",
    "listening": false,
    "dance": true,
    "existential": true,
    "transcendent": false,
    "industrial": true,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "House, Techno, Synthwave, Industrial and everything in between.",
    "city": "Haifa",
    "link": "https://soundcloud.com/kioskradio/outsiders-mediteranos-w-mayss?in=mmaayyss/sets/mixes-dance"
  },
  {
    "date": "07/13/2022",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Guest Mix",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "Pain...",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-13072022"
  },
  {
    "date": "06/06/2021",
    "radio": null,
    "radioName": "Aperitif de le Mort",
    "showName": "AM-035: Mayss",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Accessing the Cosmos through Detroit Techno!",
    "city": "Montreal",
    "link": "https://soundcloud.com/aperitifdelamort/mayss?in=mmaayyss/sets/mixes-dance&si=f81c13ef0a874fd3b7e9c484829a9c2e&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
  },
  {
    "date": "05/24/2022",
    "radio": null,
    "radioName": "Obey Records",
    "showName": "OBEY RECORDS Ep. 57: MAYSS",
    "listening": false,
    "dance": true,
    "existential": false,
    "transcendent": false,
    "industrial": true,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": false,
    "guest": true,
    "description": "Slow Burning dance & deconstructed industrial music.",
    "city": "Los Angeles",
    "link": null
  },
  {
    "date": "05/04/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Interstellar Funk",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "Tribal, psychedelic, slow burning rhythms.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-interstellar-funk-04052023"
  },
  {
    "date": "12/02/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Onsy",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "Stillness and beauty.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-onsy-02112023"
  },
  {
    "date": "12/20/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Ali Asfour",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "Ali Asfour, from Palestine, presents Music and words, dedicated to a free Palestine!",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/ali-asfour-curated-by-mayssa?in=mmaayyss/sets/mayss-invites&si=7849234826d94c6aac8171fa28716660&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
  },
  {
    "date": "02/01/2024",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Basel Rihani",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "An hour mix by Jordanian DJ, Basel Rihani.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-basel-rihani-01022024"
  },
  {
    "date": "04/04/2024",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: badlcukwind",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "An hour that weaves through various styles and sounds with the Jordanian/Palestinian DJ and curator badlcukwind.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-badlcukwind-04042024?in=mmaayyss/sets/mayss-invites&si=d066fd955ce548858769e07bed031d7b&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
  },
  {
    "date": "05/02/2024",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: xlmxhkfi",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "Dreamy & stunning mix by the Lebanese producer xlmxhkfi",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-xlmxhkfi-in-the-ruins-of-a-tender-hearts02052024?in=mmaayyss/sets/mayss-invites&si=17d03e6f5df14229a0ca39c4251b8135&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
  },
  {
    "date": "06/14/2024",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Big Murk",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "Jordanian Bristol based DJ, with an hour of insane beats!",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-big-murk-14062024"
  },
  {
    "date": "07/04/2024",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Zszs ",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": "Tribal, psychedelic housey music by the Syrian DJ ZsZs",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-invites-zszs-04072024"
  },
  {
    "date": "09/09/2024",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss invites: Assifeh (Stormtrap)",
    "listening": false,
    "dance": false,
    "existential": false,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": false,
    "mayssInvites": true,
    "guest": false,
    "description": null,
    "city": "Tbilisi",
    "link": null,
  },
  {
    "date": "02/02/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Monthly Show [02.02.2023]",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "Happy, bright, transcendental frequencies recorded when I was so happy about moving back home.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-02022023"
  },
  {
    "date": "03/02/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Monthly Show [02.03.2023]",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "Existential diary entry, capturing a little bit of pain but beauty too.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-monthly-show-02032023"
  },
  {
    "date": "04/02/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Monthly show [02.04.2023]",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "One of my favorite mixes. I had recently moved back home, and now facing the traumatic  years I had abroad.  It starts with Elvis smashing pianos mirror how I felt, inner turbulence. But the turbulence gradually quiets down and the mix ends in stillness \u2014 as I needed to feel. This recording was a profound cryto purge and achieve inner stillness.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-monthly-show-02042023"
  },
  {
    "date": "06/01/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss w/ Just George [01.06.2023]",
    "listening": true,
    "dance": true,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "Fun, funky, sexy and groovy mix recorded one of my favorite humans, just George. ",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-w-just-george-1062024"
  },
  {
    "date": "07/06/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Monthly Show [06.07.2023]",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "My friend Odai Masri had just died,  I sought refuge in the sounds of crying saxophones.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-monthly-show-06072023"
  },
  {
    "date": "09/04/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss w/ Death Harpe [04.09.2023]",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": true,
    "industrial": false,
    "cosmic": true,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "Recorded during a beautiful little romance.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-w-death-harpe-04092023"
  },
  {
    "date": "10/05/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Monthly Show [05.10.2023]",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "A disturbed love mix.",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-monthly-show-05102023"
  },
  {
    "date": "12/07/2023",
    "radio": "Mutant Radio",
    "radioName": "Mutant Radio",
    "showName": "Mayss Monthly Show (12/2023)",
    "listening": true,
    "dance": false,
    "existential": true,
    "transcendent": false,
    "industrial": false,
    "cosmic": false,
    "pinned": false,
    "kioskResidency": false,
    "mutantMonthly": true,
    "mayssInvites": false,
    "guest": false,
    "description": "An hour of music by Coil. Coil forever!",
    "city": "Tbilisi",
    "link": "https://soundcloud.com/mutantradio/mayss-monthly-show-07122023"
  }
];

