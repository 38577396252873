import React, { useState } from 'react';
import sortBy from '../assets/data/sortBy'; // Data for sorting
import sortMood from '../assets/data/sortMood'; // Data for moods
import { useNavigate } from 'react-router-dom';
import Thea from '../assets/graphics/theas/thea-dj.png'; // Thea image
import CloudTop from "../assets/graphics/cloud-footer-old.png"; // Cloud image for footer
import './ListMixes.css'; // CSS file for styling
import CloudsBottom from "../assets/graphics/cloud-down-old.png";
import { mixes } from "../assets/data/mixes"; // Import mixes

// Import the generic results component
import MixesResults from '../components/Results/MixesResults';

const ListMixes = () => {
    const [sortByText, setSortByText] = useState('Sort Mixes By: (Select One)'); // Title text
    const [moodText, setMoodText] = useState('Select Mood:'); // Title for mood
    const [isFixed, setIsFixed] = useState(false); // To check if text is fixed on click
    const [showResults, setShowResults] = useState(false); // To show the results div
    const [showMoodSelection, setShowMoodSelection] = useState(false); // To show mood options
    const [selectedOption, setSelectedOption] = useState(null); // Store the selected option

    const navigate = useNavigate(); // For navigation

    // Handle hover for sortBy buttons
    const handleSortByEnter = (description) => {
        if (!isFixed) {
            setSortByText(description); // Change title on hover
        }
    };

    const handleSortByLeave = () => {
        if (!isFixed) {
            setSortByText('Sort Mixes By: (Select One)'); // Reset title on mouse leave
        }
    };

    // Handle click for sortBy buttons
    const handleSortByClick = (description, type) => {
        setSortByText(description); // Set text as fixed on click
        setIsFixed(true);

        console.log(`Sort By Clicked: ${type}`); // Log the selected option

        // If 'mood' is selected, show mood options
        if (type === 'mood') {
            setShowMoodSelection(true); // Show the mood selection div
            setShowResults(false); // Hide the results div if it's open
        } else {
            setSelectedOption(type); // Set the selected option for non-mood options
            console.log(`Selected Option Set: ${type}`); // Log the selected option
            setShowMoodSelection(false); // Hide mood selection
            setShowResults(true); // Show the results div for other sort types
        }
    };

    // Handle hover for mood buttons
    const handleMoodEnter = (description) => {
        setMoodText(description); // Change mood title on hover
    };

    const handleMoodLeave = () => {
        setMoodText('Select Mood:'); // Reset mood title on mouse leave
    };

    // Handle click for mood buttons
    const handleMoodClick = (description, moodType) => {
        setMoodText(description); // Set mood title as fixed on click
        setSelectedOption(moodType); // Set the selected mood option
        console.log(`Mood Clicked: ${moodType}`); // Log the selected mood option
        setShowResults(true); // Show the results after mood is selected
    };

    return (
        <>
            <div className='container-mixes'>
                {/* Thea Character Image and Title */}
                <div className="thea">
                    <img src={Thea} alt="Thea DJ" className="thea-image-dj" />
                    <h1 className='title-sort'>{sortByText}</h1>
                </div>

                {/* SortBy Image Buttons */}
                <div className="sort-grid">
                    {sortBy.map((item, index) => (
                        <div className="grid-item-sort" key={index}>
                            <img
                                src={item.image}
                                alt={item.description}
                                className="grid-image-mixes"
                                onMouseEnter={() => handleSortByEnter(item.description)} // Hover to change title
                                onMouseLeave={handleSortByLeave} // Reset title on mouse leave
                                onClick={() => handleSortByClick(item.description, item.type)} // Click to fix the title and set the type
                                style={{ cursor: 'pointer' }} // Change cursor to pointer
                            />
                        </div>
                    ))}
                </div>

                {/* Mood Selection (If "Mood" Sort is Clicked) */}
                {showMoodSelection && (
                    <div className="mood-selection">
                        <h1 className='mood-sort'>{moodText}</h1> {/* Mood Title */}
                        <div className="mood-grid">
                            {sortMood.map((mood, index) => (
                                <div className="grid-item-mood" key={index}>
                                    <img
                                        src={mood.image}
                                        alt={mood.description}
                                        className="grid-image-mixes"
                                        onMouseEnter={() => handleMoodEnter(mood.description)} // Hover to change mood title
                                        onMouseLeave={handleMoodLeave} // Reset mood title on mouse leave
                                        onClick={() => handleMoodClick(mood.description, mood.type)} // Click to fix the mood title and set mood type
                                        style={{ cursor: 'pointer' }} // Change cursor to pointer
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Conditionally Render the MixesResults Component */}
                {showResults && (
                    <div className="mixResults">
                        <div className='cloudsTop'>
                            <img src={CloudTop} alt="cloudTop" className="cloud-top-mixes" />
                        </div>

                        <div className="results-mixes">
                            <MixesResults mixes={mixes} selectedOption={selectedOption} />
                        </div>

                        <div className='cloudsBottom'>
                            <img src={CloudsBottom} alt="cloudBottom" className="cloud-bottom-mixes" />
                        </div>
                    </div>
                )}

                <div className='space-footer-mixes'>
                    <div className='cloudsTop'>
                        <img src={CloudTop} alt="cloudTop" className="cloud-footer-mixes" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListMixes;
