import React, { useState, useEffect } from 'react';
import './MixesResults.css'; // CSS for styling

const MixesResults = ({ mixes, selectedOption }) => {
    // Separate active index for each group
    const [kioskActiveIndex, setKioskActiveIndex] = useState(null);
    const [mayssInvitesActiveIndex, setMayssInvitesActiveIndex] = useState(null);
    const [mutantMonthlyActiveIndex, setMutantMonthlyActiveIndex] = useState(null);
    const [otherActiveIndex, setOtherActiveIndex] = useState({}); // Track active index for each radio group

    // New activeIndex state for "all" and other options
    const [generalActiveIndex, setGeneralActiveIndex] = useState(null);

    useEffect(() => {
        console.log("Selected Option:", selectedOption); // Log selected option for debugging
    }, [selectedOption]);

    if (!mixes || mixes.length === 0) {
        return <div>No mixes available.</div>; // Fallback if mixes is undefined or empty
    }

    // Sort the mixes by date (most recent first)
    const sortedMixes = [...mixes].sort((a, b) => new Date(b.date) - new Date(a.date));

    let filteredMixes = [];

    if (selectedOption === 'radio') {
        // Group by radioName if selectedOption is 'radio'
        const kioskResidency = [];
        const mutantMayssInvites = [];
        const mutantMonthly = [];
        const otherRadios = {};

        sortedMixes.forEach(mix => {
            if (mix.kioskResidency) {
                kioskResidency.push(mix);  // Group by Kiosk Radio: Outsiders
            } else if (mix.mayssInvites) {
                mutantMayssInvites.push(mix);  // Group by Mutant Radio: Mayss Invites
            } else if (mix.mutantMonthly) {
                mutantMonthly.push(mix);  // Group by Mutant Radio: Monthly Show
            } else {
                if (!otherRadios[mix.radioName]) {
                    otherRadios[mix.radioName] = [];
                }
                otherRadios[mix.radioName].push(mix);
            }
        });

        return (
            <div className="radio-grouped-container">
                {/* Render Kiosk Radio: Outsiders */}
                {kioskResidency.length > 0 && (
                    <div className="radio-group">
                        <h2 className="radio-title">Kiosk Radio: Outsiders</h2>
                        {kioskResidency.map((mix, index) => (
                            <div key={index}>
                                <div
                                    className={`mix-item ${kioskActiveIndex === index ? 'active' : ''}`}
                                    onClick={() => setKioskActiveIndex(kioskActiveIndex === index ? null : index)}
                                >
                                    <p className="mix-date">{mix.date}</p>
                                    <p className="mix-show-name">{mix.showName}</p>
                                    <p className="mix-radio-name">{mix.radioName}</p>
                                    <p className={`mix-plus-sign ${kioskActiveIndex === index ? 'active' : ''}`}>
                                        {kioskActiveIndex === index ? '×' : '+'}
                                    </p>
                                </div>
                                {kioskActiveIndex === index && (
                                    <div className="mix-details">
                                        <div className="mix-description">
                                            <p>{mix.description}</p>
                                        </div>
                                        <div className="soundcloud-embed">
                                            <iframe
                                                width="60%"
                                                height="100"
                                                scrolling="no"
                                                frameBorder="no"
                                                allow="autoplay"
                                                src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(
                                                    mix.link
                                                )}&color=%23ff0066&auto_play=false`}
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {/* Render Mutant Radio: Mayss Invites */}
                {mutantMayssInvites.length > 0 && (
                    <div className="radio-group">
                        <h2 className="radio-title">Mutant Radio: Mayss Invites</h2>
                        {mutantMayssInvites.map((mix, index) => (
                            <div key={index}>
                                <div
                                    className={`mix-item ${mayssInvitesActiveIndex === index ? 'active' : ''}`}
                                    onClick={() => setMayssInvitesActiveIndex(mayssInvitesActiveIndex === index ? null : index)}
                                >
                                    <p className="mix-date">{mix.date}</p>
                                    <p className="mix-show-name">{mix.showName}</p>
                                    <p className="mix-radio-name">{mix.radioName}</p>
                                    <p className={`mix-plus-sign ${mayssInvitesActiveIndex === index ? 'active' : ''}`}>
                                        {mayssInvitesActiveIndex === index ? '×' : '+'}
                                    </p>
                                </div>
                                {mayssInvitesActiveIndex === index && (
                                    <div className="mix-details">
                                        <div className="mix-description">
                                            <p>{mix.description}</p>
                                        </div>
                                        <div className="soundcloud-embed">
                                            <iframe
                                                width="60%"
                                                height="100"
                                                scrolling="no"
                                                frameBorder="no"
                                                allow="autoplay"
                                                src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(
                                                    mix.link
                                                )}&color=%23ff0066&auto_play=false`}
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {/* Render Mutant Radio: Monthly Show */}
                {mutantMonthly.length > 0 && (
                    <div className="radio-group">
                        <h2 className="radio-title">Mutant Radio: Monthly Show</h2>
                        {mutantMonthly.map((mix, index) => (
                            <div key={index}>
                                <div
                                    className={`mix-item ${mutantMonthlyActiveIndex === index ? 'active' : ''}`}
                                    onClick={() => setMutantMonthlyActiveIndex(mutantMonthlyActiveIndex === index ? null : index)}
                                >
                                    <p className="mix-date">{mix.date}</p>
                                    <p className="mix-show-name">{mix.showName}</p>
                                    <p className="mix-radio-name">{mix.radioName}</p>
                                    <p className={`mix-plus-sign ${mutantMonthlyActiveIndex === index ? 'active' : ''}`}>
                                        {mutantMonthlyActiveIndex === index ? '×' : '+'}
                                    </p>
                                </div>
                                {mutantMonthlyActiveIndex === index && (
                                    <div className="mix-details">
                                        <div className="mix-description">
                                            <p>{mix.description}</p>
                                        </div>
                                        <div className="soundcloud-embed">
                                            <iframe
                                                width="60%"
                                                height="100"
                                                scrolling="no"
                                                frameBorder="no"
                                                allow="autoplay"
                                                src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(
                                                    mix.link
                                                )}&color=%23ff0066&auto_play=false`}
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {/* Render other radios */}
                {Object.keys(otherRadios).map(radioName => (
                    <div key={radioName} className="radio-group">
                        <h2 className="radio-title">{radioName}</h2>
                        {otherRadios[radioName].map((mix, index) => (
                            <div key={index}>
                                <div
                                    className={`mix-item ${otherActiveIndex[radioName] === index ? 'active' : ''}`}
                                    onClick={() =>
                                        setOtherActiveIndex({
                                            ...otherActiveIndex,
                                            [radioName]: otherActiveIndex[radioName] === index ? null : index,
                                        })
                                    }
                                >
                                    <p className="mix-date">{mix.date}</p>
                                    <p className="mix-show-name">{mix.showName}</p>
                                    <p className="mix-radio-name">{mix.radioName}</p>
                                    <p className={`mix-plus-sign ${otherActiveIndex[radioName] === index ? 'active' : ''}`}>
                                        {otherActiveIndex[radioName] === index ? '×' : '+'}
                                    </p>
                                </div>
                                {otherActiveIndex[radioName] === index && (
                                    <div className="mix-details">
                                        <div className="mix-description">
                                            <p>{mix.description}</p>
                                        </div>
                                        <div className="soundcloud-embed">
                                            <iframe
                                                width="60%"
                                                height="100"
                                                scrolling="no"
                                                frameBorder="no"
                                                allow="autoplay"
                                                src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(
                                                    mix.link
                                                )}&color=%23ff0066&auto_play=false`}
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    } else if (selectedOption === 'all') {
        // Display all mixes in chronological order if selectedOption is 'all'
        filteredMixes = sortedMixes;
    } else {
        // For other selected options (e.g., 'existential', 'dance', etc.)
        filteredMixes = sortedMixes.filter(mix => mix[selectedOption] === true || typeof mix[selectedOption] === 'number');

        // If it's a number, sort by that number with the smallest number at the top
        if (filteredMixes.length > 0 && typeof filteredMixes[0][selectedOption] === 'number') {
            filteredMixes.sort((a, b) => a[selectedOption] - b[selectedOption]);
        }
    }

    return (
        <div className="mixes-list">
            {filteredMixes.map((mix, index) => (
                <div key={index}>
                    <div
                        className={`mix-item ${index === generalActiveIndex ? 'active' : ''}`}
                        onClick={() => setGeneralActiveIndex(index === generalActiveIndex ? null : index)}
                    >
                        <p className="mix-date">{mix.date}</p>
                        <p className="mix-show-name">{mix.showName}</p>
                        <p className="mix-radio-name">{mix.radioName}</p>

                        <p className={`mix-plus-sign ${index === generalActiveIndex ? 'active' : ''}`}>
                            {index === generalActiveIndex ? '×' : '+'}
                        </p>
                    </div>
                    {index === generalActiveIndex && (
                        <div className="mix-details">
                            <div className="mix-description">
                                <p>{mix.description}</p>
                            </div>
                            <div className="soundcloud-embed">
                                <iframe
                                    width="60%"
                                    height="100"
                                    scrolling="no"
                                    frameBorder="no"
                                    allow="autoplay"
                                    src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(
                                        mix.link
                                    )}&color=%23ff0066&auto_play=false`}
                                ></iframe>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default MixesResults;
