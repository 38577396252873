import './Header.css';
import star from '../../assets/graphics/toolbar/toolbar-star-n.png';
import back from '../../assets/graphics/toolbar/toolbar-back-n.png';
import { useNavigate, useLocation } from 'react-router-dom';  // Import useNavigate and useLocation

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Function to navigate back or to landing page if no history
    const handleBackClick = () => {
        if (window.history.length > 1) {
            navigate(-1);  // Go to the previous page if there's history
        } else {
            navigate('/');  // Navigate to the landing page if no previous history
        }
    };

    // Handle star click: rotate and navigate back if on /about, otherwise go to /about
    const handleStarClick = () => {
        if (location.pathname === '/about') {
            if (window.history.length > 1) {
                navigate(-1);  // Go back if on the About page and history exists
            } else {
                navigate('/');  // Go to landing page if no history
            }
        } else {
            navigate('/about');  // Navigate to the About page
        }
    };

    return (
        <header className="header">
            <button className="back" onClick={handleBackClick}>
                <img src={back} alt="Back" className="backButton" />
            </button>
            <button className="star" onClick={handleStarClick}>
                <img src={star} alt="Star" className="starButton" />
            </button>
        </header>
    );
};

export default Header;
