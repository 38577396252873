import React, { useEffect } from 'react';
import passportImage from '../assets/graphics/about/passport.png';
import phoneImage from '../assets/graphics/about/phone.png';
import codeImage from '../assets/graphics/about/code.png';
import cloudTop from '../assets/graphics/cloud-footer.png';
import './About.css';  // Import your styles

// Embedded contact object
const contact = {
    about: "Jordanian.DJ, producer, curator, writer and web developer.  Also, the founder of Dreaming Live — a podcast dedicated to uncompromised creative expression  hosting guests like David Lynch, Martin Rev and Lydia Lunch. ",
    contact: [
        { text: "mayssn@gmail.com", link: null },
        { text: "Instagram Mayss", link: "https://www.instagram.com/mayss_88" },
        { text: "Soundcloud Dreaming Live", link: "https://soundcloud.com/dreaming-live" },
        { text: "Soundcloud Mayss", link: "https://soundcloud.com/mmaayyss" },
        { text: "Bandcamp", link: "https://dreaminglive.bandcamp.com/" }
    ]
};

const About = () => {
    useEffect(() => {
        console.log("Contact object:", contact);  // Check if the contact object is available
    }, []);

    // Use optional chaining to handle undefined contact.contact safely
    const contactList = contact?.contact || [];

    return (
        <>
            <div className="about-page">
                <div className="aboutMe">
                    <div className="about-content">
                        <div className="passport-section">
                            <img src={passportImage} alt="Passport" className="passport-image" />
                        </div>
                        <div className="about-div">
                            <div className="title">ABOUT ME</div>
                            <div className="about-text">{contact.about}</div>
                        </div>
                    </div>

                    <div className="contact-section">
                        <div className="phone-section">
                            <img src={phoneImage} alt="Phone" className="phone-image" />
                        </div>
                        <div className="contact-info">
                            {contactList.length > 0 ? (
                                contactList.map((item, index) => (
                                    <div key={index} className="contact-item">
                                        {item.link ? <a href={item.link}>{item.text}</a> : item.text}
                                    </div>
                                ))
                            ) : (
                                <div>No contact information available</div>
                            )}
                        </div>
                    </div>

                    <div className="website-section">
                        <div className="phone-section">
                            <img src={codeImage} alt="Code" className="code-image" />
                        </div>
                        <div className="website-text">
                            WEBSITE BY ME
                        </div>
                    </div>
                </div>
            </div>

            <div className='space-footer-mixes'>
                <div className='cloudsTop'>
                    <img src={cloudTop} alt="cloudTop" className="cloud-footer-mixes" />
                </div>
            </div>
        </>
    );
};

export default About;
