const sortBy = [
    {
        description: "Sort By: Featured",
        image: require('../graphics/dj/pinned.png'),
        type: "pinned"
    },
    {
        description: "Sort By: Mood",
        image: require('../graphics/dj/mood.png'),
        type: "mood"
    },
    {
        description: "Sort By: Radio",
        image: require('../graphics/dj/radio.png'),
        type: "radio"
    },
    {
        description: "Sort By: Most Recent",
        image: require('../graphics/dj/all.png'),
        type: "all"
    },
];

export default sortBy;

